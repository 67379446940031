/**
 * Installations Reducer
 *
 */
import { Map, fromJS } from "immutable";
import * as CONST from "actions/action_constants";

const installations = (state = Map({}), action) => {
  const { list, installationID, installationData } = action;
  switch (action.type) {
    case CONST.INSTALLATIONS_FETCH_SUCCESS:
      return state.merge(list);
    case CONST.INSTALLATION_CREATE_SUCCESS:
      const _installationID = installationData._id;
      return state.merge(
        fromJS({ [_installationID]: { ...installationData } })
      );
    // TODO: Figure out how revertCopy will work for installations.
    // case CONST.PROJECT_CREATE_REVERT_COPY:
    //   return state.set("revertCopy", state.get(action.projectID));
    // case CONST.PROJECT_REVERT_CHANGES:
    //   const revertData = state.get("revertCopy").toJS();
    //   revertData.isSaved = true;
    //   const revertProjectID = revertData._id;
    //   return state.set(revertProjectID, fromJS(revertData));
    case CONST.LOGOUT_SUCCESS:
      return state.clear();
    case CONST.INSTALLATION_PROP_CHANGE:
      const selector = [];
      selector.push(installationID, action.prop);
      return state
        .setIn(selector, action.value)
        .setIn([installationID, "isSaved"], false);
    case CONST.INSTALLATION_ADD_PROJECT_SUCCESS:
      return state.set(installationData._id, fromJS(installationData));
    case CONST.INSTALLATION_DELETE_SUCCESS:
      return state.remove(installationID);
    case CONST.INSTALLATION_DELETE_PROJECT_SUCCESS:
      return state.set(installationID, installationData);
    default:
      return state;
  }
};

export default installations;
