import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchLibrarySaga,
  assetUpdateSaga,
  createAssetSaga,
  resetAssetSaga,
  assetDeleteSaga
} from "sagas/library";
import { fetchQuizSaga } from "sagas/quizEditor";
import {
  themeUpdateSaga,
  themeEditorSaga,
  themeStyleAddSaga,
  resetEditorSaga
} from "sagas/themeEditor";
import {
  fetchProjectBrowserSaga,
  publishProjectSaga,
  updateProjectSaga,
  setProjectOrderSaga,
  projectAddStoriesSaga,
  projectDeleteStoriesSaga,
  createProjectSaga,
  deleteProjectSaga
} from "sagas/projects";
import {
  loginSaga,
  initAuthCheck,
  authCheckSaga,
  logoutSaga,
  handlePasswordResetRequestedSaga
} from "sagas/auth";
import {
  fetchStoryBrowserSaga,
  fetchStoryDataSaga,
  createStorySaga,
  // updateStorySaga, // Doesn't appear to be used as of 11/26/2019
  deleteStorySaga,
  setStorySceneOrderSaga,
  createSceneSaga,
  updateSceneSaga,
  deleteSceneSaga,
  duplicateSceneSaga,
  createSubSceneSaga,
  updateSubSceneSaga,
  deleteSubSceneSaga,
  sceneChangeLayoutSaga,
  sceneChangeClassNamesSaga,
  sceneChangeStylesSaga,
  resetSceneOrderSaga,
  setSceneSubSceneOrderSaga,
  setSceneElementAssetSaga,
  setElementValueSaga,
  deleteSceneElementAssetSaga,
  saveSettingsSaga,
  saveDesignSettingsSaga,
  saveQuestionsSceneSaga
} from "sagas/storyEditor";
import {
  fetchStoryCategoriesSaga,
  createStoryCategorySaga,
  deleteStoryCategoriesSaga,
  updateStoryCategorySaga
} from "sagas/story-categories";
import { fetchAnalyticsSaga } from "sagas/analytics";
import { handleAccountManagerRequestSaga } from "sagas/account";
import {
  handleUserManagerAddRequestSaga,
  handleUserManagerDeleteRequestSaga,
  handleUserEditorSaveRequestSaga
} from "sagas/users";
import { handleUserPreferenceChangeSaga } from "sagas/userPreferences";
import { fetchFeedbackSaga, deleteFeedbackSaga } from "sagas/feedback";
import { fetchCollectionBrowserSaga } from "sagas/collectionEditor";
import {
  fetchInstallationsSaga,
  createInstallationSaga,
  addInstallationProjectSaga,
  deleteInstallationProjectSaga,
  updateInstallationSaga,
  deleteInstallationSaga
} from "sagas/installations";
import * as CONST from "actions/action_constants";
import { fetchAppConfig } from "api"; // take, fork,

/**
 AppInitSaga handles any tasks needed to get the application ready to be used AFTER authentication
 @param {Object} Action
 */
export function* appInitSaga(action) {
  try {
    const { response, error } = yield call(fetchAppConfig);

    if (response) {
      yield put({ type: CONST.APP_INIT_SUCCESS, ...response });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.APP_INIT_FAILURE, message });
  }
}

/**
 * Root Saga is a watcher of all sagas
 * single entry point to start all sagas....
 * @NOTE not sure why but ONLY the takeEvery seems to work...
 */
export default function* rootSaga() {
  // AuthCheck
  yield takeEvery(CONST.INIT_AUTH_CHECK, initAuthCheck);
  yield takeEvery(CONST.SAGA_AUTH_CHECK, authCheckSaga);
  // Base
  yield takeEvery(CONST.APP_INIT_REQUEST, appInitSaga);

  // auth
  yield takeEvery(CONST.LOGIN_REQUEST, loginSaga);
  yield takeEvery(CONST.LOGOUT_REQUEST, logoutSaga);

  yield takeEvery(
    CONST.PASSWORD_RESET_REQUESTED,
    handlePasswordResetRequestedSaga
  );

  // Projects
  yield takeEvery(CONST.PROJECT_BROWSER_REQUEST, fetchProjectBrowserSaga);
  yield takeEvery(CONST.PROJECT_SET_STORY_ORDER_REQUEST, setProjectOrderSaga);
  yield takeEvery(CONST.PROJECT_PUBLISH_REQUEST, publishProjectSaga);
  yield takeEvery(CONST.PROJECT_UPDATE_REQUEST, updateProjectSaga);
  yield takeEvery(CONST.PROJECT_ADD_STORIES_REQUEST, projectAddStoriesSaga);
  yield takeEvery(
    CONST.PROJECT_DELETE_STORIES_REQUEST,
    projectDeleteStoriesSaga
  );
  yield takeEvery(CONST.PROJECT_CREATE_REQUEST, createProjectSaga);
  yield takeEvery(CONST.PROJECT_DELETE_REQUEST, deleteProjectSaga);

  // Installations
  yield takeEvery(CONST.INSTALLATIONS_FETCH_REQUEST, fetchInstallationsSaga);
  yield takeEvery(CONST.INSTALLATION_CREATE_REQUEST, createInstallationSaga);
  yield takeEvery(
    CONST.INSTALLATION_ADD_PROJECT_REQUEST,
    addInstallationProjectSaga
  );
  yield takeEvery(
    CONST.INSTALLATION_DELETE_PROJECT_REQUEST,
    deleteInstallationProjectSaga
  );
  yield takeEvery(CONST.INSTALLATION_UPDATE_REQUEST, updateInstallationSaga);
  yield takeEvery(CONST.INSTALLATION_DELETE_REQUEST, deleteInstallationSaga);

  // Theme Editor
  yield takeEvery(CONST.THEME_EDITOR_REQUEST, themeEditorSaga);
  yield takeEvery(CONST.UPDATE_THEME_REQUEST, themeUpdateSaga);
  yield takeEvery(CONST.ADD_STYLE_REQUEST, themeStyleAddSaga);
  yield takeEvery(CONST.THEME_EDITOR_RESET, resetEditorSaga);

  // Library
  yield takeEvery(CONST.LIBRARY_ASSET_UPDATE_REQUEST, assetUpdateSaga);
  yield takeEvery(CONST.LIBRARY_FETCH_REQUEST, fetchLibrarySaga);
  yield takeEvery(CONST.LIBRARY_ASSET_EDITOR_RESET, resetAssetSaga);
  yield takeEvery(CONST.LIBRARY_ASSET_CREATE_REQUEST, createAssetSaga);
  yield takeEvery(CONST.LIBRARY_DELETE_MULTIPLE_REQUEST, assetDeleteSaga);

  // Story Browser
  yield takeEvery(CONST.STORY_BROWSER_REQUEST, fetchStoryBrowserSaga);
  yield takeEvery(CONST.COLLECTION_BROWSER_REQUEST, fetchCollectionBrowserSaga);

  // Story Data
  yield takeEvery(CONST.STORY_DATA_REQUEST, fetchStoryDataSaga);
  yield takeEvery(CONST.STORY_CREATE_REQUEST, createStorySaga);
  // yield takeEvery(CONST.STORY_UPDATE_REQUEST, updateStorySaga); // Doesn't appear to be used as of 11/26/2019
  yield takeEvery(CONST.STORY_DELETE_REQUEST, deleteStorySaga);
  yield takeEvery(CONST.STORY_SETTINGS_SAVE_REQUEST, saveSettingsSaga);
  yield takeEvery(
    CONST.STORY_DESIGN_SETTINGS_SAVE_REQUEST,
    saveDesignSettingsSaga
  );

  // Story Categories
  yield takeEvery(
    CONST.STORY_CATEGORY_BROWSER_REQUEST,
    fetchStoryCategoriesSaga
  );
  yield takeEvery(CONST.STORY_CATEGORY_CREATE_REQUEST, createStoryCategorySaga);
  yield takeEvery(
    CONST.STORY_CATEGORIES_DELETE_REQUEST,
    deleteStoryCategoriesSaga
  );
  yield takeLatest(
    CONST.STORY_CATEGORY_EDITOR_SAVE_REQUEST,
    updateStoryCategorySaga
  );
  // USER_EDITOR_SAVE_REQUEST

  // Story Questions Scene
  yield takeEvery(
    CONST.STORY_QUESTION_SCENE_SAVE_REQUEST,
    saveQuestionsSceneSaga
  );

  // Quiz
  yield takeEvery(CONST.QUIZ_EDITOR_REQUEST, fetchQuizSaga);

  // Scene Browser
  yield takeEvery(CONST.STORY_SET_SCENE_ORDER_REQUEST, setStorySceneOrderSaga);
  yield takeLatest(CONST.STORY_RESET_ORDER_REQUEST, resetSceneOrderSaga);

  // Scene Data
  yield takeEvery(CONST.SCENE_CREATE_REQUEST, createSceneSaga);
  yield takeLatest(CONST.SCENE_UPDATE_REQUEST, updateSceneSaga);
  yield takeLatest(CONST.SCENE_DELETE_REQUEST, deleteSceneSaga);
  yield takeLatest(CONST.SCENE_DUPLICATE_REQUEST, duplicateSceneSaga);
  yield takeLatest(CONST.SCENE_CHANGE_LAYOUT_REQUEST, sceneChangeLayoutSaga);
  // yield takeLatest(
  //   CONST.SCENE_CHANGE_CLASS_NAMES_REQUEST,
  //   sceneChangeClassNamesSaga
  // );
  yield takeLatest(
    CONST.SCENE_STYLE_SETTINGS_SAVE_REQUEST,
    sceneChangeStylesSaga
  );

  // SubScene Data
  yield takeEvery(CONST.SUBSCENE_CREATE_REQUEST, createSubSceneSaga);
  // yield takeEvery(CONST.SUBSCENE_UPDATE_REQUEST, updateSubSceneSaga);
  yield takeEvery(CONST.SUBSCENE_DELETE_REQUEST, deleteSubSceneSaga);
  yield takeLatest(
    CONST.SCENE_SET_SUBSCENE_ORDER_REQUEST,
    setSceneSubSceneOrderSaga
  );

  // Elements
  yield takeEvery(
    CONST.STORY_EDITOR_SET_ELEMENT_VALUE_REQUEST,
    setElementValueSaga
  );
  yield takeLatest(
    CONST.SCENE_SET_ASSET_ELEMENT_REQUEST,
    setSceneElementAssetSaga
  );
  yield takeLatest(
    CONST.SCENE_DELETE_ASSET_ELEMENT_REQUEST,
    deleteSceneElementAssetSaga
  );

  // Feedbacks
  yield takeLatest(CONST.FEEDBACK_REQUEST, fetchFeedbackSaga);
  yield takeLatest(CONST.FEEDBACK_DELETE_REQUEST, deleteFeedbackSaga);

  // Analytics
  yield takeLatest(CONST.ANALYTICS_REQUEST, fetchAnalyticsSaga);

  yield takeLatest(
    CONST.ACCOUNT_MANAGER_REQUEST,
    handleAccountManagerRequestSaga
  );

  yield takeLatest(
    CONST.USER_MANAGER_ADD_REQUEST,
    handleUserManagerAddRequestSaga
  );
  yield takeLatest(
    CONST.USER_MANAGER_DELETE_REQUEST,
    handleUserManagerDeleteRequestSaga
  );
  yield takeLatest(
    CONST.USER_EDITOR_SAVE_REQUEST,
    handleUserEditorSaveRequestSaga
  );
  yield takeEvery(CONST.USER_PREFERENCE_CHANGE, handleUserPreferenceChangeSaga);
}
