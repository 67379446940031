/**
 * API Call class- CLIENTSIDE
 * this file contains all the various fetch actions we use to call our API
 * this file is made up of api call functions that are exported to be used in various sagas
 */

// TODO: need to figure out how to have the api load test data when running tests...
// otherwise these should go away
// import DEFAULT_DATA from "data/test.cms.config";
// import THEME_EDITOR_DATA from "data/test.theme.editor.data.cms";
// import QUIZ_EDITOR_DATA from 'data/test.quiz.editor.data.cms';
// import ASSET_DATA from 'data/test.assets';
// import STORY_DATA from 'data/test.story.data.cms';
import axios from "axios";
import config from "config";
import { loadState } from "store/localStorage";
import _camelCase from "lodash/camelCase";
import Logger from "utils/logger";

// until we get rid of references, we set to empty
// these external data object files cause uglify to choke
const THEME_EDITOR_DATA = {};
const QUIZ_EDITOR_DATA = {};
const ASSET_DATA = {};
const STORY_DATA = {};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
const useFlatFiles = false; // read from the config / .env values
// --

export const getProtocol = apiHost => {
  const proto = apiHost === "localhost" ? "" : "https:";
  return `${proto}//`;
};

export const getPort = use => {
  let port = use ? config(use) : config("port") || window.location.port;
  if (port && port !== "80" && port !== "443") {
    // ignore if 80 or 443
    port = `:${port}`;
  }
  return port ?? "";
};

const getBaseAPIUrl = () => {
  const apiHost = config("apiHost");
  const apiPort = getPort("apiPort");
  const protoocol = getProtocol(apiHost);

  return `${protoocol}${apiHost}${apiPort}`;
};

/**
 * FetchAccessToken - send request to the API to insure refresh token is valid and return a new acces token
 * @param {Object} refresh token provided from Auth0 when logged in
 * @return {Object} it will either be an error
 * an object containing a new access_token, id_token , expires_in
 */
export const fetchAccessToken = refreshToken => {
  if (!refreshToken) {
    throw new Error("No Refresh Token provided.");
  }

  // similar payload will be sent to Auth0 from the API
  const axOpts = {
    method: "get",
    url: `${getBaseAPIUrl()}/validate/`,
    headers: { refresh_token: refreshToken }
  };

  return axios(axOpts).then(response => {
    if (response.status >= 400) {
      throw new Error("Bad response from server");
    }
    if (response.data.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  });
};

export const triggerPasswordReset = email => {
  const axOpts = {
    method: "get",
    url: `${getBaseAPIUrl()}/cms/reset-password/${email}`
  };

  return axios(axOpts)
    .then(response => ({ response: response.data }))
    .catch(error => ({ error }));
};

//----------------------------------------------------------------
// CONFIG
// includes basic account and user information
export const fetchAppConfig = () => {
  const local = loadState();
  Logger.debug({ local }, "[API] fetchAppConfig");
  const { accessToken } = local;

  //if (accessToken) {
  const axOpts = {
    method: "get",
    url: `${getBaseAPIUrl()}/cms/config`,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return axios(axOpts)
    .then(response => ({ response: response.data }))
    .catch(error => {
      throw new Error(error);
    });
  // } else {
  //   throw new Error("Unable to load configuration, missing tokens.");
  // }
};

//----------------------------------------------------------------
// ACCOUNT

export const fetchClient = email => {
  const local = loadState();
  const { accessToken, idToken } = local;
  return delay(500).then(() => {
    if (email) {
      // if (DEFAULT_DATA.clients[email]) {
      //   return DEFAULT_DATA.clients[email];
      // } else {
      //   throw new Error("Unknown Client:" + email);
      // }
    } else {
      throw new Error("No client provided.");
    }
  });
};

//----------------------------------------------------------------
// PROJECTS

export const fetchProjects = username => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/projects/${username}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("No username provided.");
};

export const createProject = data => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/projects/`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load story, missing tokens.");
};

export const updateProject = (projectID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    // Strip out '<p><br></p>' string that Quill RTE adds.
    for (const property in data) {
      if (typeof data[property] === "object") {
        // Data is potentially localized. Loop over props.
        for (const subProperty in data[property]) {
          if (data[property][subProperty] === "<p><br></p>")
            data[property][subProperty] = "";
        }
      } else if (data[property] === "<p><br></p>") data[property] = "";
    }

    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/projects/${projectID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load story, missing tokens.");
};

export const deleteProject = (storyIDs = []) => {
  const local = loadState();
  const { accessToken } = local;

  let url = `${getBaseAPIUrl()}/cms/projects/${storyIDs[0]}`;
  let data;

  if (storyIDs.length > 1) {
    url = `${getBaseAPIUrl()}/cms/projects`;
    data = { ids: storyIDs };
  }

  if (accessToken) {
    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data // NOTE: i am not sure how valid it is to post data on a delete method
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load project, missing tokens.");
};

export const publishProject = (projectID, projectType, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url:
        projectType === "story"
          ? `${getBaseAPIUrl()}/cms/bundle/${projectID}`
          : `${getBaseAPIUrl()}/cms/bundle/collection/${projectID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
        // id_token: idToken
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("No username provided.");
};

export const projectAddStories = (projectID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/projects/${projectID}/stories`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to projectAddStories, missing tokens.");
};

export const deleteStoriesFromProject = (projectID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const url = `${getBaseAPIUrl()}/cms/projects/${projectID}/stories`;

    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Missing tokens.");
};

//----------------------------------------------------------------
// INSTALLATIONS

export const fetchInstallations = () => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/installations`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Error occurred fetching installations.");
};

export const createInstallation = data => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/installations/`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to create installation; missing tokens.");
};

export const updateInstallation = (installationID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/installations/${installationID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to update installation, missing tokens.");
};

export const addInstallationProject = (installationID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/installations/${installationID}/project`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to add installation project; missing tokens.");
};

export const deleteInstallationProject = installationID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const url = `${getBaseAPIUrl()}/cms/installations/${installationID}/project`;

    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to delete installation project; missing tokens.");
};

export const deleteInstallation = installationID => {
  const local = loadState();
  const { accessToken } = local;

  const url = `${getBaseAPIUrl()}/cms/installations/${installationID}`;

  if (accessToken) {
    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to delete installation; missing tokens.");
};

//----------------------------------------------------------------
// ASSETS

export const fetchLibrary = params => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/assets`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load assets, missing tokens.");
};

export const createAsset = assetData => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/assets`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: assetData
    };

    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load assets, missing tokens.");
};

export const updateAsset = (assetIndex, assetData, queId) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    if (assetData._id) {
      delete assetData._id;
    }

    // Trimming <br>'s
    for (const property in assetData) {
      if (assetData.hasOwnProperty(property)) {
        if (assetData[property] === "<p><br></p>") assetData[property] = "";
      }
    }

    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/assets/${assetIndex}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
        // id_token: idToken
      },
      data: assetData
    };
    return axios(axOpts)
      .then(response => ({
        response: {
          success: true
        }
      }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to update asset, missing tokens.");
};

export const deleteAsset = (assetIndex, queId) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "delete",
      url: `${getBaseAPIUrl()}/cms/assets/${assetIndex}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ success: true, queId }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load assets, missing tokens.");
};
//----------------------------------------------------------------
// THEMES
/**
 * @description a call to the API to return the user's themes, styles and layouts and any templates
 * @param  clientID
 */
export const fetchThemeEditor = params => delay(400).then(() => {});

export const dummyDelay = params =>
  delay(1000).then(() => {
    Logger.debug("API - dummyDelay completed");
  });

export const updateTheme = (themeIndex, themeData, queId) =>
  // simulate an async call then return...
  delay(1000).then(() => ({ success: true, themeIndex, themeData, queId }));

// export const saveFullThemeStyle = (style, themeIndex) =>{
//     //const styleData = {[property] : value}
//     // simulate an async call then return...
//     return  delay(600).then(() => {
//             return style;
//     })
// }

export const saveThemeStyleValue = (property, value, themeIndex) => {
  const styleData = { [property]: value };
  // simulate an async call then return...
  return delay(600).then(() => styleData);
};

export const addNewStyle = (styleName = "new", styleSet) => {
  styleSet.name = styleName;
  styleSet.id = _camelCase(styleName) + Math.random(3); // randmon
  styleSet.userDefined = true;
  // simulate an async call then return...
  return delay(600).then(() => ({
    styleName: _camelCase(styleName),
    styleSet
  }));
};

// --------------------------------------------------------------------
export const fetchQuiz = () => delay(2000).then(() => QUIZ_EDITOR_DATA());

export const updateQuizQuestion = (questionIndex, questionData, queId) => {
  return delay(1000).then(() => ({
    success: true,
    questionIndex,
    questionData,
    queId
  }));
};

// ---------------------------------------------------------------------
// STORIES

/**
 * this function will return the all the story objects for a given accountID
 * @return {object}
 */
export const fetchStoryBrowser = () => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/stories`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load stories, missing tokens.");
};

/**
 * this function will return the all the collection objects for a given accountID
 * @return {object}
 */
export const fetchCollectionBrowser = () => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/collections`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load collections, missing tokens.");
};

/**
 * this function will return the story data for a given storyID
 * @return {object}
 */
export const fetchStoryData = storyID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load story, missing tokens.");
};

/**
 * Create Story
 */
export const createStory = data => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/stories/`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load story, missing tokens.");
};

/**
 * Update Story
 */
export const updateStory = (storyID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    // Strip out '<p><br></p>' string that Quill RTE adds.
    for (const property in data) {
      if (typeof data[property] === "object") {
        // Data is potentially localized. Loop over props.
        for (const subProperty in data[property]) {
          if (data[property][subProperty] === "<p><br></p>")
            data[property][subProperty] = "";
        }
      } else if (data[property] === "<p><br></p>") data[property] = "";
    }

    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };

    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  // throw new Error('Unable to load story, missing tokens.');
};

/**
 * Delete Story
 * @param {string} storyID
 * we may end up allowing the ability to pass an array of multiple ids to be removed at once...
 */
export const deleteStory = (storyIDs = []) => {
  const local = loadState();
  const { accessToken } = local;

  let url = `${getBaseAPIUrl()}/cms/stories/${storyIDs[0]}`;
  let data;

  if (storyIDs.length > 1) {
    url = `${getBaseAPIUrl()}/cms/stories`;
    data = { ids: storyIDs };
  }

  if (accessToken) {
    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load story, missing tokens.");
};

// ---------------------------------------------------------------------
// SCENES

/**
 * this function will return all the scene data objects for a given storyID
 * @return {Object}
 */
export const fetchStoryScenes = storyID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}/scenes`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scenes, missing tokens.");
};

export const createScene = (data, storyID) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}/scenes`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

export const createSubScene = (data, storyID, sceneID) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}/scenes/${sceneID}/subscenes`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

/**
 * Update Scene
 */
export const updateScene = (sceneID, data, elementID) => {
  const local = loadState();
  const { accessToken, idToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "put",
      url: elementID
        ? `${getBaseAPIUrl()}/cms/scenes/${sceneID}/elements/${elementID}`
        : `${getBaseAPIUrl()}/cms/scenes/${sceneID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

/**
 * Duplicate Scene
 */
export const duplicateScene = (storyID, sceneID) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    let url = `${getBaseAPIUrl()}/cms/stories/${storyID}/scenes/${sceneID}/duplicate`;

    const axOpts = {
      method: "post",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to duplicate scene, missing access token.");
};

/**
 * Delete Scene
 */
export const deleteScene = (sceneIDs = [], storyID) => {
  const local = loadState();
  const { accessToken, idToken } = local;

  if (accessToken) {
    let url = `${getBaseAPIUrl()}/cms/stories/${storyID}/scenes/${sceneIDs[0]}`;
    let data;

    if (sceneIDs.length > 1) {
      url = `${getBaseAPIUrl()}/cms/stories/${storyID}/scenes`;
      data = { ids: sceneIDs };
    }

    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };

    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

/**
 * Delete SubScene
 */
export const deleteSubScene = (
  sceneIDs = [],
  parentID,
  isRotationGallery = false
) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    let url = `${getBaseAPIUrl()}/cms/scenes/${parentID}/subscenes/${
      sceneIDs[0]
    }`;
    const data = {};

    if (sceneIDs.length > 1) {
      url = `${getBaseAPIUrl()}/cms/scenes/${parentID}/subscenes`;
      data.ids = sceneIDs;
    }

    data.isRotationGallery = isRotationGallery;

    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

// ---------------------------------------------------------------------
// SCENE ELEMENTS
export const deleteElementAsset = (sceneID, elementID) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const url = `${getBaseAPIUrl()}/cms/scenes/${sceneID}/elements/${elementID}`;
    let data;

    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

// ---------------------------------------------------------------------
// STORY CATEGORIES

export const fetchStoryCategories = storyID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}/categories`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to fetch story categories, missing tokens.");
};

/**
 * Create Story Category
 */
export const createStoryCategory = storyID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/stories/${storyID}/categories`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to create story category, missing tokens.");
};

/**
 * Update Story Category
 */
export const updateStoryCategory = (categoryID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/story-categories/${categoryID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };

    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to update story category, missing tokens.");
};

/**
 * Delete Story Categories
 */
export const deleteStoryCategory = categoryID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "delete",
      url: `${getBaseAPIUrl()}/cms/story-categories/${categoryID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to delete story category, missing tokens.");
};

// ---------------------------------------------------------------------
// FEEDBACK
export const fetchFeedback = () => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "get",
      url: `${getBaseAPIUrl()}/cms/feedback`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("No username provided.");
};

export const deleteFeedback = (feedbackIDs = []) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    let url = `${getBaseAPIUrl()}/cms/feedback/${feedbackIDs[0]}`;
    let data;

    if (feedbackIDs.length > 1) {
      url = `${getBaseAPIUrl()}/cms/feedback`;
      data = { ids: feedbackIDs };
    }

    const axOpts = {
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

export const fetchAnalytics = (responseProp, match, stats, weekGroup) => {
  const local = loadState();
  const { accessToken } = local;

  const group = encodeURI(
    JSON.stringify({
      properties: ["lang"]
    })
  );

  if (accessToken) {
    const url = `${getBaseAPIUrl()}/cms/analytics?${
      match ? `match=${match}` : ""
    }${stats ? `&stats=${stats}` : ""}${
      weekGroup ? `&group=${weekGroup}` : ""
    }`;
    // const url = `${getBaseAPIUrl()}/cms/analytics?`
    //   + match
    //     ? `match=${match}&`
    //     : ''
    //   + stats
    //     ? `stats=${stats}&`
    //     : ''
    //   + weekGroup
    //     ? `group=${weekGroup}`
    //     : '';

    const axOpts = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ [responseProp]: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

export const fetchAnalyticsUI = (match, distinct) => {
  const local = loadState();
  const { accessToken } = local;

  const group = encodeURI(
    JSON.stringify({
      properties: ["lang"]
    })
  );

  if (accessToken) {
    const url = `${getBaseAPIUrl()}/cms/analytics/distinct?${
      match ? `match=${match}&distinct=${distinct}` : ""
    }`;
    // const url = `${getBaseAPIUrl()}/cms/analytics/distinct?`
    //   + match
    //     ? `match=${match}&distinct=${distinct}`
    //     : '';

    const axOpts = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ analyticsUIdata: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load scene, missing tokens.");
};

export const fetchAccountUsers = () => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const url = `${getBaseAPIUrl()}/cms/account/users`;

    const axOpts = {
      method: "GET",
      url,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to load account data. Missing token.");
};

export const createUser = data => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "post",
      url: `${getBaseAPIUrl()}/cms/account/users`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Unable to create user. Missing token.");
};

/**
 * Update User
 */
export const updateUser = (userID, data) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/account/users/${userID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Missing token.");
};

export const deleteUser = userID => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "delete",
      url: `${getBaseAPIUrl()}/cms/account/users/${userID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Missing token.");
};

export const updateUserPreference = (userID, selector, name, value) => {
  const local = loadState();
  const { accessToken } = local;

  if (accessToken) {
    const axOpts = {
      method: "put",
      url: `${getBaseAPIUrl()}/cms/userpreferences/${userID}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data: { selector, name, value }
    };
    return axios(axOpts)
      .then(response => ({ response: response.data }))
      .catch(error => ({ error }));
  }
  throw new Error("Missing token.");
};
