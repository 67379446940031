import * as CONST from "actions/action_constants";

export const installationsFetchRequest = username => ({
  type: CONST.INSTALLATIONS_FETCH_REQUEST,
  username
});

export const installationCreateRequest = inputData => ({
  type: CONST.INSTALLATION_CREATE_REQUEST,
  inputData
});

// export const setProjectOrder = (projectID, oldIndex, newIndex) => ({
//   type: CONST.PROJECT_SET_STORY_ORDER_REQUEST,
//   projectID,
//   oldIndex,
//   newIndex
// });

// export const publishProject = (projectID, data) => ({
//   type: CONST.PROJECT_PUBLISH_REQUEST,
//   projectID,
//   data
// });

// export const createProjectRevertCopy = projectID => ({
//   type: CONST.PROJECT_CREATE_REVERT_COPY,
//   projectID
// });

// export const revertProjectChanges = () => ({
//   type: CONST.PROJECT_REVERT_CHANGES
// });

// Used to update Installation settings
export const setInstallationProp = (installationID, prop, value) => ({
  type: CONST.INSTALLATION_PROP_CHANGE,
  installationID,
  prop,
  value
});

export const installationUpdateRequest = installationID => ({
  type: CONST.INSTALLATION_UPDATE_REQUEST,
  installationID
});

export const installationAddProjectRequest = (installationID, projectID) => {
  return {
    type: CONST.INSTALLATION_ADD_PROJECT_REQUEST,
    installationID,
    projectID
  };
};

export const installationDeleteProject = installationID => ({
  type: CONST.INSTALLATION_DELETE_PROJECT_REQUEST,
  installationID
});
