import { put, call, select } from "redux-saga/effects";
import {
  fetchInstallations,
  createInstallation,
  updateInstallation,
  addInstallationProject,
  deleteInstallationProject,
  deleteInstallation
} from "api";
import * as CONST from "actions/action_constants";
import * as selectors from "./selectors";

export function* fetchInstallationsSaga(action) {
  try {
    const { response, error } = yield call(fetchInstallations, action.username);

    if (response) {
      const { list } = response;
      yield put({
        type: CONST.INSTALLATIONS_FETCH_SUCCESS,
        list
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.INSTALLATIONS_FETCH_ERROR, message });
  }
}

export function* createInstallationSaga(action) {
  try {
    const { inputData } = action;
    const { typeID, title } = inputData;

    const installationData = {
      typeID,
      title
    };

    const { response, error } = yield call(
      createInstallation,
      installationData
    );

    if (response) {
      yield put({
        type: CONST.INSTALLATION_CREATE_SUCCESS,
        installationData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.INSTALLATION_CREATE_ERROR, message });
  }
}

export function* updateInstallationSaga(action) {
  try {
    const { installationID } = action;

    const installation = yield select(
      selectors.getInstallation,
      installationID
    );
    const { response, error } = yield call(
      updateInstallation,
      installationID,
      installation
    );

    if (response) {
      yield put({
        type: CONST.INSTALLATION_UPDATE_SUCCESS,
        installationData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.INSTALLATION_UPDATE_ERROR, message });
  }
}

export function* addInstallationProjectSaga(action) {
  try {
    const { installationID, projectID } = action;
    const { response, error } = yield call(
      addInstallationProject,
      installationID,
      projectID
    );

    if (response) {
      yield put({
        type: CONST.INSTALLATION_ADD_PROJECT_SUCCESS,
        projectData: response
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.INSTALLATION_ADD_PROJECT_ERROR, message });
  }
}

export function* deleteInstallationProjectSaga(action) {
  try {
    let { installationID } = action;
    const { response, error } = yield call(
      deleteInstallationProject,
      installationID
    );

    if (response) {
      yield put({
        type: CONST.INSTALLATION_DELETE_PROJECT_SUCCESS,
        installationData: response,
        installationID
      });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.INSTALLATION_DELETE_PROJECT_ERROR, message });
  }
}

export function* deleteInstallationSaga(action) {
  try {
    const { selected: installationID } = action;

    const { response, error } = yield call(deleteInstallation, installationID);

    if (response) {
      yield put({ type: CONST.INSTALLATION_DELETE_SUCCESS, installationID });
    } else {
      throw new Error(error);
    }
  } catch (error) {
    const { message } = error;
    yield put({ type: CONST.INSTALLATION_DELETE_ERROR, message });
  }
}
