import { Map } from "immutable";
import _map from "lodash/map";
import _forEach from "lodash/forEach";
import _cloneDeep from "lodash/cloneDeep";
import _isEqual from "lodash/isEqual";
import _filter from "lodash/filter";

import { localizeTextProps } from "helpers/localizationHelper";
import { getVisibleItems } from "helpers/cardBrowserHelper";
import * as selectors from "sagas/selectors";

/**
 * InstanceBrowserMapStateToProps
 * @param {object} state
 * @param {object} ownProps
 */
export const instanceBrowserMapStateToProps = (state, ownProps) => {
  const account = state.getIn(["data", "account"], Map);
  const user = state.getIn(["data", "user"], Map);

  const isCollections = ownProps.match.path.indexOf("/collections") > -1;
  const isProjects = ownProps.match.path.indexOf("/projects") > -1;
  const isInstallations = ownProps.match.path.indexOf("/installations") > -1;
  const instanceType = isCollections
    ? "collection"
    : isProjects
    ? "project"
    : isInstallations
    ? "installation"
    : "story";

  // The assests are needed for localization.
  const assets = state.getIn(["data", "assets"], Map).toJS();

  // TODO: Figure out why the cardBrowserConfig and isLoading props are
  // being pulled from the storyEditor UI state.
  const storyEditor = state.getIn(["ui", "storyEditor"], Map({})).toJS();
  const { cardBrowserConfig, isLoading, to } = storyEditor;

  const cardBrowserConfigUserPrefsSelector = [
    `${instanceType}Manager`,
    "browser",
    "cardBrowserConfig"
  ];
  const cardBrowserConfigUserPrefs = state
    .getIn(
      ["ui", "userPreferences", ...cardBrowserConfigUserPrefsSelector],
      Map({})
    )
    .toJS();
  if (cardBrowserConfigUserPrefs.cardSizeMultiplier) {
    cardBrowserConfig.cardSizeMultiplier =
      cardBrowserConfigUserPrefs.cardSizeMultiplier;
  }
  if (cardBrowserConfigUserPrefs.sortByType) {
    cardBrowserConfig.sortByType = cardBrowserConfigUserPrefs.sortByType;
  }
  if (cardBrowserConfigUserPrefs.sortByOrder) {
    cardBrowserConfig.sortByOrder = cardBrowserConfigUserPrefs.sortByOrder;
  }

  const baseConfig = state.getIn(["base", "config"], Map).toJS();
  const storyTypes = state.getIn(["base", "storyTypes"], Map({})).toJS();
  const projectTypes = state.getIn(["base", "projectTypes"], Map({})).toJS();
  const { langID } = baseConfig; //
  const localizedProps = storyEditor.localizedProps || ["title"]; // read from storyEditor state, is it based on type???

  const localizedInstances = [];

  if (isProjects) {
    const projects = state.getIn(["data", "projects"], Map).toJS();
    _map(projects, project => {
      localizedInstances.push(
        localizeTextProps(langID, localizedProps, project)
      );
    });
  } else if (isInstallations) {
    // No localizaton is necessary, but we still need to
    // add the installation instances to the array.
    const installations = state.getIn(["data", "installations"], Map).toJS();
    _map(installations, installation => {
      localizedInstances.push(installation);
    });
  } else {
    const stories = state.getIn(["data", "stories"], Map).toJS();
    _map(stories, (story, storyId) => {
      if (storyId !== "revertCopy") {
        const storyTempType = selectors.getStoryTypeParam(state, story.type);
        if (storyTempType === "collection" && isCollections) {
          localizedInstances.push(
            localizeTextProps(langID, localizedProps, story)
          );
        }
        if (storyTempType !== "collection" && !isCollections) {
          localizedInstances.push(
            localizeTextProps(langID, localizedProps, story)
          );
        }
      }
    });
  }

  // TODO: Figure out why to is "/stories" and not "stories".
  const section = isCollections
    ? "collections"
    : isProjects
    ? "projects"
    : isInstallations
    ? "installations"
    : to;

  const visibleItems = !isLoading
    ? getVisibleItems(localizedInstances, assets, cardBrowserConfig, section)
    : [];

  return {
    accountID: account.get("_id", null),
    username: user.get("email", null),
    instanceType,
    isLoading,
    isCollections,
    isProjects,
    isInstallations,
    items: visibleItems,
    selected: cardBrowserConfig.selected,
    minCardSize: cardBrowserConfig.cardSizeMultiplier
      ? cardBrowserConfig.cardSizeMultiplier
      : 210,
    settings: state.get("base", Map).toJS(), // need to see if this can just be passed down
    cardBrowserConfigUserPrefsSelector,
    storyBrowserUIState: cardBrowserConfig,
    // TODO: Generalize this as instanceTypes.
    storyTypes,
    projectTypes
  };
};
