/**
 * Selectors allow for Sagas to get another part of the state
 * we might end up needing to break in to files for clarity
 */

import { Map } from "immutable";
import Logger from "utils/logger";

export const getSiteList = (state, filter = "all") => {
  const sites = state.getIn(["data", "projects"], Map);

  switch (filter) {
    case "active":
      return state.filter(site => site.get("siteStatus") === "2").toJS();
    // return _filter(styles, {'userDefined':true});
    case "live":
      return state.filter(site => site.get("site_status") === "1").toJS();
    // return _filter(styles, {'userDefined':false});
    default:
      return state.toJS(); // this is shallow so not totally what we want
  }
};

export const getStory = (state, storyID) => {
  return state.getIn(["data", "stories", storyID]).toJS();
};

export const getAsset = (state, assetID) => {
  return state.getIn(["data", "assets", assetID], Map).toJS();
};

export const getStoryList = (state, filter = "all") => {
  const sites = state.getIn(["data", "sites"], Map);

  switch (filter) {
    case "active":
      return state.filter(site => site.get("siteStatus") === "2").toJS();
    // return _filter(styles, {'userDefined':true});
    case "live":
      return state.filter(site => site.get("site_status") === "1").toJS();
    // return _filter(styles, {'userDefined':false});
    default:
      return state.toJS(); // this is shallow so not totally what we want
  }
};

export const getStoryCategory = (state, categoryID) => {
  return state.getIn(["data", "storyCategories", categoryID]).toJS();
};

export const getScene = (state, sceneID) => {
  return state.getIn(["data", "scenes", sceneID]).toJS();
};

export const getProject = (state, projectID) => {
  Logger.debug({ projectID }, "[SAGAS] getProject");
  return state.getIn(["data", "projects", projectID]).toJS();
};

export const getInstallation = (state, installationID) => {
  Logger.debug({ installationID }, "[SAGAS] getInstallation");
  return state.getIn(["data", "installations", installationID]).toJS();
};

export const getRevertCopy = state =>
  state.getIn(["data", "scenes", "revertCopy"]).toJS();

/**
 * Type Selectors
 */
export const getStoryTypeTitle = (state, typeParam) =>
  state.getIn(["base", "storyTypes", typeParam, "title"], "");

export const getStoryTypeId = (state, typeParam) =>
  state.getIn(["base", "storyTypes", typeParam, "_id"], "");

export const getProjectTypeId = (state, typeParam) =>
  state.getIn(["base", "projectTypes", typeParam, "_id"], "");

export const getStoryTypeParam = (state, typeID) => {
  // find the object with id that matches
  if (!typeID) return;
  const storyType = state
    .getIn(["base", "storyTypes"])
    .findEntry(type => type.get("_id") === typeID);

  // return friendly
  return storyType ? storyType[0] : "";
};

export const getProjectTypeParam = (state, typeID) => {
  // find the object with id that matches
  if (!typeID) return;
  const projectType = state
    .getIn(["base", "projectTypes"])
    .findEntry(type => type.get("_id") === typeID);
  // return friendly
  return projectType ? projectType[0] : "";
};

export const getStoryTypes = (state, filter = "all") => {
  const storyTypes = state.getIn(["base", "storyTypes"]);
  return storyTypes ? storyTypes.toJS() : {};
};

export const getSceneTypeTitle = (state, typeParam) =>
  state.getIn(["base", "sceneTypes", typeParam, "title"], "");

export const getSceneTypeParam = (state, typeID) => {
  // find the object with id that matches
  if (!typeID) return;
  const sceneType = state
    .getIn(["base", "sceneTypes"])
    .findEntry(type => type.get("_id") === typeID);

  // return friendly
  return sceneType ? sceneType[0] : "";
};

export const getSceneTypeId = (state, sceneTypeParam) =>
  state.getIn(["base", "sceneTypes", sceneTypeParam, "_id"]);

export const getSceneTypes = (state, filter = "all") => {
  const sceneTypes = state.getIn(["base", "sceneTypes"]);
  return sceneTypes ? sceneTypes.toJS() : {};
};

/*
 * method to allow for other reducers to select from the data library...
 * TODO: move into the selectors file
 */
export const selectLibraryItems = (state, filter = "all") => {
  if (!state) return;

  const library = state.get("library");
  return library ? library.toJS() : {}; // could pass a filter for list
};

export const selectThemes = (state = Map({}), filter = "all") => {
  const themes = state.get("themes");
  return themes ? themes.toJS() : {}; // could pass a filter for list
};

// LAYOUTS
export const getLayoutParam = (state, layoutID) => {
  // find the object with id that matches
  if (!layoutID) return;
  const layouts = state
    .getIn(["base", "layouts"])
    .findEntry(type => type.get("_id") === layoutID);
  // return friendly
  return layouts ? layouts[0] : "";
};
export const getLayoutTemplate = (state, layoutID, param) => {
  // find the object with id that matches
  if (!layoutID) return;
  if (!param) {
    param = getLayoutParam(state, layoutID);
  }
  const layoutTemplate =
    typeof state.getIn(["base", "layouts", param, "template"], []) === "string"
      ? state.getIn(["base", "layouts", param, "template"], [])
      : state.getIn(["base", "layouts", param, "template"], []).toJS();

  return layoutTemplate;
};
export const getLayoutElements = (state, layoutID, param) => {
  // find the object with id that matches
  if (!layoutID) return;
  if (!param) {
    param = getLayoutParam(state, layoutID);
  }
  const layoutElements = state.getIn(["base", "layouts", param, "elements"])
    ? state.getIn(["base", "layouts", param, "elements"], {}).toJS()
    : {};

  return layoutElements;
};
export const getLayoutId = (state, layoutParam) =>
  state.getIn(["base", "layouts", layoutParam, "_id"]);

export const getUser = (state, userID) =>
  state.getIn(["data", "accountusers", userID], {}).toJS();

export const getUserInfo = state => state.getIn(["data", "user"], {}).toJS();
