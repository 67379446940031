// used to see if the user previously logged in
export const INIT_AUTH_CHECK = "INIT_AUTH_CHECK";
export const SAGA_AUTH_CHECK = "SAGA_AUTH_CHECK";
export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";

export const INIT_AUTH_CHECK_FAILURE = "INIT_AUTH_CHECK_FAILURE";
export const AUTH_CHECK_FAILURE = "AUTH_CHECK_FAILURE";
export const AUTH_RENEW_FAILURE = "AUTH_RENEW_FAILURE";

export const APP_INIT_REQUEST = "APP_INIT_REQUEST";
export const APP_INIT_SUCCESS = "APP_INIT_SUCCESS";
export const APP_INIT_FAILURE = "APP_INIT_FAILURE";
export const SET_STATE = "SET_STATE";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const PASSWORD_RESET_REQUESTED = "PASSWORD_RESET_REQUESTED";

export const VALIDATE_CREDENTIALS = "VALIDATE_CREDENTIALS";

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const CLIENT_REQUEST = "CLIENT_REQUEST";
export const CLIENT_SUCCESS = "CLIENT_SUCCESS";
export const CLIENT_FAILURE = "CLIENT_FAILURE";

export const PROJECT_BROWSER_REQUEST = "PROJECT_BROWSER_REQUEST";
export const PROJECT_BROWSER_SUCCESS = "PROJECT_BROWSER_SUCCESS";
export const PROJECT_BROWSER_ERROR = "PROJECT_BROWSER_ERROR";

export const ACCOUNT_MANAGER_ENTRY = "SITE_MANAGER_ENTRY";
export const ACCOUNT_MANAGER_EXIT = "SITE_MANAGER_EXIT";

export const SET_UI_ERROR_REQUEST = "SET_UI_ERROR_REQUEST";
export const RESET_UI_ERROR = "RESET_UI_ERROR";

// theme editor
//
export const THEME_EDITOR_REQUEST = "THEME_EDITOR_REQUEST";
export const THEME_EDITOR_SUCCESS = "THEME_EDITOR_SUCCESS";
export const THEME_EDITOR_ERROR = "THEME_EDITOR_ERROR";

export const SET_THEME_STYLE_VALUE_REQUEST = "SET_THEME_STYLE_VALUE_REQUEST";
export const SET_THEME_STYLE_VALUE_SUCCESS = "SET_THEME_STYLE_VALUE_SUCCESS";
export const SET_THEME_STYLE_VALUE_FAILURE = "SET_THEME_STYLE_VALUE_FAILURE";

export const PREVIEW_THEME_STYLE = "PREVIEW_THEME_STYLE";

export const SET_THEME_STYLE_REQUEST = "SET_THEME_STYLE_REQUEST";
export const SET_THEME_STYLE_SUCCESS = "SET_THEME_STYLE_SUCCESS"; // no api call to set a style....
export const SET_THEME_STYLE_FAILURE = "SET_THEME_STYLE_FAILURE"; // so may not need these

export const ADD_STYLE_REQUEST = "ADD_STYLE_REQUEST";
export const ADD_STYLE_SUCCESS = "ADD_STYLE_SUCCESS";
export const ADD_STYLE_FAILURE = "ADD_STYLE_FAILURE";

export const ADD_THEME_REQUEST = "ADD_THEME_REQUEST";
export const ADD_THEME_SUCCESS = "ADD_THEME_SUCCESS";
export const ADD_THEME_FAILURE = "ADD_THEME_FAILURE";

export const UPDATE_THEME_REQUEST = "UPDATE_THEME_REQUEST";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";
export const UPDATE_THEME_FAILURE = "UPDATE_THEME_FAILURE";

export const THEME_EDITOR_RESET = "THEME_EDITOR_RESET";
export const REVERT_THEME = "REVERT_THEME";
export const COPY_THEME = "COPY_THEME";
export const REMOVE_COPY_THEME = "REMOVE_COPY_THEME";
export const RESET_PREVIEW_DATA = "RESET_PREVIEW_DATA";

export const RESET_PROCESS_COMPLETE = "RESET_PROCESS_COMPLETE";

// queue
export const QUEUE_REQUEST = "QUEUE_REQUEST";
export const QUEUE_RESPONSE = "QUEUE_RESPONSE";
export const CLEAR_QUEUE_ITEM = "CLEAR_QUEUE_ITEM";
export const QUEUE_ITEM_SHOWN = "QUEUE_ITEM_SHOWN";

// language
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

// library
export const LIBRARY_FETCH_REQUEST = "LIBRARY_FETCH_REQUEST";
export const LIBRARY_FETCH_SUCCESS = "LIBRARY_FETCH_SUCCESS";
export const LIBRARY_FETCH_ERROR = "LIBRARY_FETCH_ERROR";
// export const RECEIVE_LIBRARY = 'RECEIVE_LIBRARY';
export const LIBRARY_ASSET_REQUEST = "LIBRARY_ASSET_REQUEST";

export const SET_LIBRARY_SORT_FILTER = "SET_LIBRARY_SORT_FILTER";
export const SET_LIBRARY_CAT_FILTER = "SET_LIBRARY_CAT_FILTER";
export const UNSET_LIBRARY_CAT_FILTER = "UNSET_LIBRARY_CAT_FILTER";
export const SET_LIBRARY_SEARCH = "SET_LIBRARY_SEARCH";
export const SET_LIBRARY_LOADING = "SET_LIBRARY_LOADING";
export const SET_LIBRARY_ASSET_LOADING = "SET_LIBRARY_ASSET_LOADING";
export const SET_LIBRARY_SLIDER_SIZE_MULTIPLIER =
  "SET_LIBRARY_SLIDER_SIZE_MULTIPLIER";

export const LIBRARY_ASSET_UPDATE_REQUEST = "LIBRARY_ASSET_UPDATE_REQUEST";
export const LIBRARY_ASSET_UPDATE_SUCCESS = "LIBRARY_ASSET_UPDATE_SUCCESS";
export const LIBRARY_ASSET_UPDATE_ERROR = "LIBRARY_ASSET_UPDATE_ERROR";

export const LIBRARY_ASSET_CREATE_REQUEST = "LIBRARY_ASSET_CREATE_REQUEST";
export const LIBRARY_ASSET_CREATE_SUCCESS = "LIBRARY_ASSET_CREATE_SUCCESS";
export const LIBRARY_ASSET_CREATE_ERROR = "LIBRARY_ASSET_CREATE_ERROR";

export const LIBRARY_SET_ASSET_VALUE_REQUEST =
  "LIBRARY_SET_ASSET_VALUE_REQUEST";
export const LIBRARY_SET_ASSET_VALUE_SUCCESS =
  "LIBRARY_SET_ASSET_VALUE_SUCCESS";
export const LIBRARY_SET_ASSET_VALUE_FAILURE =
  "LIBRARY_SET_ASSET_VALUE_FAILURE";

export const REVERT_ASSET = "REVERT_ASSET";
export const COPY_ASSET = "COPY_ASSET";
export const REMOVE_COPY_ASSET = "REMOVE_COPY_ASSET";
export const LIBRARY_ASSET_EDITOR_RESET = "LIBRARY_ASSET_EDITOR_RESET";

export const LIBRARY_ADD_PAGE = "LIBRARY_ADD_PAGE";
export const LIBRARY_SELECT_TOGGLE = "LIBRARY_SELECT_TOGGLE";
export const LIBRARY_EXCLUSIVE_SELECT_TOGGLE =
  "LIBRARY_EXCLUSIVE_SELECT_TOGGLE";
export const LIBRARY_SHIFT_SELECT_TOGGLE = "LIBRARY_SHIFT_SELECT_TOGGLE";
export const LIBRARY_SET_SCROLL = "LIBRARY_SET_SCROLL";
export const LIBRARY_SET_BROWSING_MODE = "LIBRARY_SET_BROWSING_MODE";
export const LIBRARY_SET_TOTAL_COUNT = "LIBRARY_SET_TOTAL_COUNT";
export const LIBRARY_DELETE_MULTIPLE_REQUEST =
  "LIBRARY_DELETE_MULTIPLE_REQUEST";
export const LIBRARY_DELETE_MULTIPLE_SUCCESS =
  "LIBRARY_DELETE_MULTIPLE_SUCCESS";
export const LIBRARY_DELETE_MULTIPLE_ERROR = "LIBRARY_DELETE_MULTIPLE_ERROR";

export const LIBRARY_CARD_BROWSER_RESET = "LIBRARY_CARD_BROWSER_RESET";

// QUIZ EDITOR
export const QUIZ_EDITOR_REQUEST = "QUIZ_EDITOR_REQUEST";
export const QUIZ_EDITOR_SUCCESS = "QUIZ_EDITOR_SUCCESS";
export const QUIZ_EDITOR_ERROR = "QUIZ_EDITOR_ERROR";

export const QUIZ_QUESTION_UPDATE_REQUEST = "QUIZ_QUESTION_UPDATE_REQUEST";
export const QUIZ_QUESTION_UPDATE_SUCCESS = "QUIZ_QUESTION_UPDATE_SUCCESS";
export const QUIZ_QUESTION_UPDATE_ERROR = "QUIZ_QUESTION_UPDATE_ERROR";

export const COPY_QUESTION = "COPY_QUESTION";
export const REMOVE_COPY_QUESTION = "REMOVE_COPY_QUESTION";
export const PREPARE_ADD_QUESTION = "PREPARE_ADD_QUESTION";

// STORIES
export const STORY_BROWSER_REQUEST = "STORY_BROWSER_REQUEST";
export const STORY_BROWSER_SUCCESS = "STORY_BROWSER_SUCCESS";
export const STORY_BROWSER_ERROR = "STORY_BROWSER_ERROR";

// COLLECTIONS
export const COLLECTION_BROWSER_REQUEST = "COLLECTION_BROWSER_REQUEST";
export const COLLECTION_BROWSER_SUCCESS = "COLLECTION_BROWSER_SUCCESS";
export const COLLECTION_BROWSER_ERROR = "COLLECTION_BROWSER_ERROR";

export const STORY_DATA_REQUEST = "STORY_DATA_REQUEST";
export const STORY_DATA_SUCCESS = "STORY_DATA_SUCCESS";
export const STORY_DATA_ERROR = "STORY_DATA_ERROR";

export const COLLECTION_DATA_REQUEST = "COLLECTION_DATA_REQUEST";
export const COLLECTION_DATA_SUCCESS = "COLLECTION_DATA_SUCCESS";
export const COLLECTION_DATA_ERROR = "COLLECTION_DATA_ERROR";

export const STORY_CREATE_REQUEST = "STORY_CREATE_REQUEST";
export const STORY_CREATE_SUCCESS = "STORY_CREATE_SUCCESS";
export const STORY_CREATE_ERROR = "STORY_CREATE_ERROR";

export const STORY_CREATE_REVERT_COPY = "STORY_CREATE_REVERT_COPY";
export const STORY_CREATE_REVERT_COPY_SUCCESS =
  "STORY_CREATE_REVERT_COPY_SUCCESS";
export const STORY_CREATE_REVERT_COPY_ERROR = "STORY_CREATE_REVERT_COPY_ERROR";

export const STORY_REVERT_CHANGES = "STORY_REVERT_CHANGES";

export const STORY_SET_PROP = "STORY_SET_PROP";
export const STORY_SET_DESIGN_PROP = "STORY_SET_DESIGN_PROP";

// Doesn't appear to be used as of 11/26/2019
// export const STORY_UPDATE_REQUEST = "STORY_UPDATE_REQUEST";
export const STORY_UPDATE_SUCCESS = "STORY_UPDATE_SUCCESS";
export const STORY_UPDATE_ERROR = "STORY_UPDATE_ERROR";

export const STORY_SET_SCENE_ORDER_REQUEST = "STORY_SET_SCENE_ORDER_REQUEST"; // called by move to do the set and call api
export const STORY_SET_SCENE_ORDER = "STORY_SET_SCENE_ORDER"; // used to change the store immediately
export const STORY_SET_SCENE_ORDER_SUCCESS = "STORY_SET_SCENE_ORDER_SUCCESS"; // used after the api is a success
export const STORY_SET_SCENE_ORDER_ERROR = "STORY_SET_SCENE_ORDER_ERROR";

export const STORY_DELETE_REQUEST = "STORY_DELETE_REQUEST";
export const STORY_DELETE_SUCCESS = "STORY_DELETE_SUCCESS";
export const STORY_DELETE_ERROR = "STORY_DELETE_ERROR";

export const SET_STORY_BROWSER_CAT_FILTER = "SET_STORY_BROWSER_CAT_FILTER";
export const UNSET_STORY_BROWSER_CAT_FILTER = "UNSET_STORY_BROWSER_CAT_FILTER";
export const SET_STORY_BROWSER_SEARCH = "SET_STORY_BROWSER_SEARCH";
export const SET_STORY_BROWSER_LOADING = "SET_STORY_BROWSER_LOADING";
export const SET_STORY_BROWSER_ISSAVED = "SET_STORY_BROWSER_ISSAVED";

export const STORY_BROWSER_SELECT_TOGGLE = "STORY_BROWSER_SELECT_TOGGLE";
export const STORY_BROWSER_EXCLUSIVE_SELECT_TOGGLE =
  "STORY_BROWSER_EXCLUSIVE_SELECT_TOGGLE";
export const STORY_BROWSER_SHIFT_SELECT_TOGGLE =
  "STORY_BROWSER_SHIFT_SELECT_TOGGLE";
export const STORY_BROWSER_SET_SCROLL = "STORY_BROWSER_SET_SCROLL";
export const STORY_BROWSER_SET_BROWSING_MODE =
  "STORY_BROWSER_SET_BROWSING_MODE";
export const STORY_BROWSER_SET_TOTAL_COUNT = "STORY_BROWSER_SET_TOTAL_COUNT";

export const STORY_EDITOR_SET_ACTIVE_SLIDE = "STORY_EDITOR_SET_ACTIVE_SLIDE";
export const STORY_EDITOR_SET_ACTIVE_ELEMENT =
  "STORY_EDITOR_SET_ACTIVE_ELEMENT";
export const STORY_EDITOR_ADD_SLIDE_REQUEST = "STORY_EDITOR_ADD_SLIDE_REQUEST";
export const STORY_EDITOR_ADD_SLIDE_SUCCESS = "STORY_EDITOR_ADD_SLIDE_SUCCESS";
export const STORY_EDITOR_ADD_SLIDE_ERROR = "STORY_EDITOR_ADD_SLIDE_ERROR";
export const STORY_EDITOR_ADD_SCENE_SUCCESS = "STORY_EDITOR_ADD_SCENE_SUCCESS";
export const STORY_RESET_ORDER_REQUEST = "STORY_RESET_ORDER_REQUEST";
export const STORY_SLIDE_RESET_ORDER = "STORY_SLIDE_RESET_ORDER";
export const STORY_SLIDE_UPDATE_ACTIVE = "STORY_SLIDE_UPDATE_ACTIVE";
export const STORY_SLIDE_REVERT = "STORY_SLIDE_REVERT"; // note changed name from  REVERT_SLIDE which was missing in the file
export const STORY_SLIDE_UPDATE_SUCCESS = "STORY_SLIDE_UPDATE_SUCCESS";

export const STORY_EDITOR_SET_ELEMENT_VALUE_REQUEST =
  "STORY_EDITOR_SET_ELEMENT_VALUE_REQUEST";
export const STORY_EDITOR_SET_ELEMENT_VALUE = "STORY_EDITOR_SET_ELEMENT_VALUE";
export const STORY_EDITOR_SET_ELEMENT_VALUE_SUCCESS =
  "STORY_EDITOR_SET_ELEMENT_VALUE_SUCCESS";
export const STORY_EDITOR_SET_ELEMENT_VALUE_FAILURE =
  "STORY_EDITOR_SET_ELEMENT_VALUE_FAILURE";

export const STORY_EDITOR_SET_NAVIGATE_TO_NEW =
  "STORY_EDITOR_SET_NAVIGATE_TO_NEW";

export const STORY_SCENE_DELETE_SUCCESS = "STORY_SCENE_DELETE_SUCCESS";

export const STORY_SETTINGS_SAVE_REQUEST = "STORY_SETTINGS_SAVE_REQUEST";
export const STORY_SETTINGS_SAVE_SUCCESS = "STORY_SETTINGS_SAVE_SUCCESS";
export const STORY_SETTINGS_SAVE_ERROR = "STORY_SETTINGS_SAVE_ERROR";

export const STORY_DESIGN_SETTINGS_SAVE_REQUEST =
  "STORY_DESIGN_SETTINGS_SAVE_REQUEST";
export const STORY_DESIGN_SETTINGS_SAVE_SUCCESS =
  "STORY_DESIGN_SETTINGS_SAVE_SUCCESS";
export const STORY_DESIGN_SETTINGS_SAVE_ERROR =
  "STORY_DESIGN_SETTINGS_SAVE_ERROR";

export const STORY_CATEGORY_BROWSER_REQUEST = "STORY_CATEGORY_BROWSER_REQUEST";
export const STORY_CATEGORIES_REQUEST_SUCCESS =
  "STORY_CATEGORIES_REQUEST_SUCCESS";
export const STORY_CATEGORIES_REQUEST_ERROR = "STORY_CATEGORIES_REQUEST_ERROR";
export const STORY_CATEGORY_CREATE_REQUEST = "STORY_CATEGORY_CREATE_REQUEST";
export const STORY_CATEGORY_CREATE_SUCCESS = "STORY_CATEGORY_CREATE_SUCCESS";
export const STORY_CATEGORY_CREATE_ERROR = "STORY_CATEGORY_CREATE_ERROR";
export const STORY_CATEGORIES_DELETE_REQUEST =
  "STORY_CATEGORIES_DELETE_REQUEST";
export const STORY_CATEGORY_DELETE_SUCCESS = "STORY_CATEGORY_DELETE_SUCCESS";
export const STORY_CATEGORIES_DELETE_ERROR = "STORY_CATEGORIES_DELETE_ERROR";
export const STORY_CATEGORY_EDITOR_DATA_INITIALIZED =
  "STORY_CATEGORY_EDITOR_DATA_INITIALIZED";
export const STORY_CATEGORY_EDITOR_DATA_CHANGED =
  "STORY_CATEGORY_EDITOR_DATA_CHANGED";
export const STORY_CATEGORY_EDITOR_DATA_REVERT_REQUEST =
  "STORY_CATEGORY_EDITOR_DATA_REVERT_REQUEST";
export const STORY_CATEGORY_EDITOR_SAVE_REQUEST =
  "STORY_CATEGORY_EDITOR_SAVE_REQUEST";
export const STORY_CATEGORY_EDITOR_SAVE_SUCCESS =
  "STORY_CATEGORY_EDITOR_SAVE_SUCCESS";
export const STORY_CATEGORY_EDITOR_SAVE_ERROR =
  "STORY_CATEGORY_EDITOR_SAVE_ERROR";

// SCENE
export const SCENE_CREATE_REQUEST = "SCENE_CREATE_REQUEST";
export const SCENE_CREATE_SUCCESS = "SCENE_CREATE_SUCCESS";
export const SCENE_CREATE_ERROR = "SCENE_CREATE_ERROR";

export const SCENE_UPDATE_REQUEST = "SCENE_UPDATE_REQUEST";
export const SCENE_UPDATE_SUCCESS = "SCENE_UPDATE_SUCCESS";
export const SCENE_UPDATE_ERROR = "SCENE_UPDATE_ERROR";

export const SCENE_DELETE_REQUEST = "SCENE_DELETE_REQUEST";
export const SCENE_DELETE_SUCCESS = "SCENE_DELETE_SUCCESS";
export const SCENE_DELETE_ERROR = "SCENE_DELETE_ERROR";

export const SUBSCENE_CREATE_REQUEST = "SUBSCENE_CREATE_REQUEST";
export const SUBSCENE_GALLERY_CREATE_REQUEST =
  "SUBSCENE_GALLERY_CREATE_REQUEST";
export const SUBSCENE_CREATE_SUCCESS = "SUBSCENE_CREATE_SUCCESS";
export const SUBSCENE_CREATE_ERROR = "SUBSCENE_CREATE_ERROR";

export const SUBSCENE_UPDATE_REQUEST = "SUBSCENE_UPDATE_REQUEST";
export const SUBSCENE_UPDATE_SUCCESS = "SUBSCENE_UPDATE_SUCCESS";
export const SUBSCENE_UPDATE_ERROR = "SUBSCENE_UPDATE_ERROR";

export const SUBSCENE_DELETE_REQUEST = "SUBSCENE_DELETE_REQUEST";
export const SUBSCENE_DELETE_SUCCESS = "SUBSCENE_DELETE_SUCCESS";
export const SUBSCENE_DELETE_ERROR = "SUBSCENE_DELETE_ERROR";

export const SCENE_CREATE_REVERT_COPY = "SCENE_CREATE_REVERT_COPY";
export const SCENE_CREATE_REVERT_COPY_FROM_ID =
  "SCENE_CREATE_REVERT_COPY_FROM_ID";

export const SCENE_DUPLICATE_REQUEST = "SCENE_DUPLICATE_REQUEST";
export const SCENE_DUPLICATE_SUCCESS = "SCENE_DUPLICATE_SUCCESS";
export const SCENE_DUPLICATE_ERROR = "SCENE_DUPLICATE_ERROR";

export const SCENE_REMOVE_COPY = "SCENE_REMOVE_COPY";
export const SCENE_REVERT = "SCENE_REVERT";

export const SCENE_CHANGE_LAYOUT_REQUEST = "SCENE_CHANGE_LAYOUT_REQUEST";

export const SLIDESHOW_SCENE_EDITOR_MOUNT = "SLIDESHOW_SCENE_EDITOR_MOUNT";

// export const SCENE_CHANGE_CLASS_NAMES_REQUEST =
//   "SCENE_CHANGE_CLASS_NAMES_REQUEST";

export const SCENE_SET_SUBSCENE_ORDER_REQUEST =
  "SCENE_SET_SUBSCENE_ORDER_REQUEST"; // called by move to do the set and call api
export const SCENE_SET_SUBSCENE_ORDER = "SCENE_SET_SUBSCENE_ORDER"; // used to change the store immediately
export const SCENE_SET_SUBSCENE_ORDER_SUCCESS =
  "SCENE_SET_SUBSCENE_ORDER_SUCCESS"; // used after the api is a success
export const SCENE_SET_SUBSCENE_ORDER_ERROR = "SCENE_SET_SUBSCENE_ORDER_ERROR";

export const PROJECT_SET_STORY_ORDER_REQUEST =
  "PROJECT_SET_STORY_ORDER_REQUEST"; // called by move to do the set and call api
export const PROJECT_SET_STORY_ORDER = "PROJECT_SET_STORY_ORDER";
export const PROJECT_SET_STORY_ORDER_SUCCESS =
  "PROJECT_SET_STORY_ORDER_SUCCESS"; // used after the api is a success
export const PROJECT_SET_STORY_ORDER_ERROR = "PROJECT_SET_STORY_ORDER_ERROR";

export const PROJECT_CREATE_REVERT_COPY = "PROJECT_CREATE_REVERT_COPY";
export const PROJECT_CREATE_REVERT_COPY_SUCCESS =
  "PROJECT_CREATE_REVERT_COPY_SUCCESS";
export const PROJECT_CREATE_REVERT_COPY_ERROR =
  "PROJECT_CREATE_REVERT_COPY_ERROR";

export const PROJECT_REVERT_CHANGES = "PROJECT_REVERT_CHANGES";

export const PROJECT_SET_PROP = "PROJECT_SET_PROP";
export const PROJECT_THEME_CHANGE_SUCCESS = "PROJECT_THEME_CHANGE_SUCCESS";

export const PROJECT_UPDATE_REQUEST = "PROJECT_UPDATE_REQUEST";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_ERROR = "PROJECT_UPDATE_ERROR";

export const PROJECT_ADD_STORIES_REQUEST = "PROJECT_ADD_STORIES_REQUEST";
export const PROJECT_ADD_STORIES_SUCCESS = "PROJECT_ADD_STORIES_SUCCESS";
export const PROJECT_ADD_STORIES_ERROR = "PROJECT_ADD_STORIES_ERROR";

export const PROJECT_DELETE_STORIES_REQUEST = "PROJECT_DELETE_STORIES_REQUEST";
export const PROJECT_DELETE_STORIES_SUCCESS = "PROJECT_DELETE_STORIES_SUCCESS";
export const PROJECT_DELETE_STORIES_ERROR = "PROJECT_DELETE_STORIES_ERROR";

export const PROJECT_CREATE_REQUEST = "PROJECT_CREATE_REQUEST";
export const PROJECT_CREATE_SUCCESS = "PROJECT_CREATE_SUCCESS";
export const PROJECT_CREATE_ERROR = "PROJECT_CREATE_ERROR";

export const PROJECT_DELETE_REQUEST = "PROJECT_DELETE_REQUEST";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_ERROR = "PROJECT_DELETE_ERROR";

export const PROJECT_PUBLISH_REQUEST = "PROJECT_PUBLISH_REQUEST";
export const PROJECT_PUBLISH_SUCCESS = "PROJECT_PUBLISH_SUCCESS";
export const PROJECT_PUBLISH_ERROR = "PROJECT_PUBLISH_ERROR";

export const INSTALLATIONS_FETCH_REQUEST = "INSTALLATIONS_FETCH_REQUEST";
export const INSTALLATIONS_FETCH_SUCCESS = "INSTALLATIONS_FETCH_SUCCESS";
export const INSTALLATIONS_FETCH_ERROR = "INSTALLATIONS_FETCH_ERROR";

export const INSTALLATION_CREATE_REQUEST = "INSTALLATION_CREATE_REQUEST";
export const INSTALLATION_CREATE_SUCCESS = "INSTALLATION_CREATE_SUCCESS";
export const INSTALLATION_CREATE_ERROR = "INSTALLATION_CREATE_ERROR";

export const INSTALLATION_UPDATE_REQUEST = "INSTALLATION_UPDATE_REQUEST";
export const INSTALLATION_UPDATE_SUCCESS = "INSTALLATION_UPDATE_SUCCESS";
export const INSTALLATION_UPDATE_ERROR = "INSTALLATION_UPDATE_ERROR";

export const INSTALLATION_PROP_CHANGE = "INSTALLATION_PROP_CHANGE";

export const INSTALLATION_ADD_PROJECT_REQUEST =
  "INSTALLATION_ADD_PROJECT_REQUEST";
export const INSTALLATION_ADD_PROJECT_SUCCESS =
  "INSTALLATION_ADD_PROJECT_SUCCESS";
export const INSTALLATION_ADD_PROJECT_ERROR = "INSTALLATION_ADD_PROJECT_ERROR";

export const INSTALLATION_DELETE_PROJECT_REQUEST =
  "INSTALLATION_DELETE_PROJECT_REQUEST";
export const INSTALLATION_DELETE_PROJECT_SUCCESS =
  "INSTALLATION_DELETE_PROJECT_SUCCESS";
export const INSTALLATION_DELETE_PROJECT_ERROR =
  "INSTALLATION_DELETE_PROJECT_ERROR";

export const INSTALLATION_DELETE_REQUEST = "INSTALLATION_DELETE_REQUEST";
export const INSTALLATION_DELETE_SUCCESS = "INSTALLATION_DELETE_SUCCESS";
export const INSTALLATION_DELETE_ERROR = "INSTALLATION_DELETE_ERROR";

export const SCENE_SET_ASSET_ELEMENT_REQUEST =
  "SCENE_SET_ASSET_ELEMENT_REQUEST";
export const SCENE_SET_ASSET_ELEMENT_SUCCESS =
  "SCENE_SET_ASSET_ELEMENT_SUCCESS";
export const SCENE_SET_ASSET_ELEMENT_ERROR = "SCENE_SET_ASSET_ELEMENT_ERROR";

export const SCENE_DELETE_ASSET_ELEMENT_REQUEST =
  "SCENE_DELETE_ASSET_ELEMENT_REQUEST";

export const SCENE_SET_INPUT_ELEMENT_REQUEST =
  "SCENE_SET_INPUT_ELEMENT_REQUEST";
export const SCENE_SET_INPUT_ELEMENT_SUCCESS =
  "SCENE_SET_INPUT_ELEMENT_SUCCESS";
export const SCENE_SET_INPUT_ELEMENT_ERROR = "SCENE_SET_INPUT_ELEMENT_ERROR";

export const SCENE_STYLE_SETTING_CHANGE = "SCENE_STYLE_SETTING_CHANGE";
export const SCENE_STYLE_SETTINGS_SAVE_REQUEST =
  "SCENE_STYLE_SETTINGS_SAVE_REQUEST";

export const SCENE_SET_IS_SAVED = "SCENE_SET_IS_SAVED";

// Feedback
export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_ERROR = "FEEDBACK_ERROR";

export const FEEDBACK_DELETE_REQUEST = "FEEDBACK_DELETE_REQUEST";
export const FEEDBACK_DELETE_SUCCESS = "FEEDBACK_DELETE_SUCCESS";
export const FEEDBACK_DELETE_ERROR = "FEEDBACK_DELETE_ERROR";

export const STORY_QUESTION_SCENE_SAVE_REQUEST =
  "STORY_QUESTION_SCENE_SAVE_REQUEST";
export const STORY_QUESTION_SCENE_SAVE_SUCCESS =
  "STORY_QUESTION_SCENE_SAVE_SUCCESS";
export const STORY_QUESTION_SCENE_SAVE_ERROR =
  "STORY_QUESTION_SCENE_SAVE_ERROR";

// Analytics
export const ANALYTICS_REQUEST = "ANALYTICS_REQUEST";
export const ANALYTICS_SUCCESS = "ANALYTICS_SUCCESS";
export const ANALYTICS_ERROR = "ANALYTICS_ERROR";

export const ACCOUNT_MANAGER_REQUEST = "ACCOUNT_MANAGER_REQUEST";
export const ACCOUNT_USERS_FETCH_SUCCESS = "ACCOUNT_USERS_FETCH_SUCCESS";
export const ACCOUNT_USERS_FETCH_ERROR = "ACCOUNT_USERS_FETCH_ERROR";

export const USER_MANAGER_ADD_REQUEST = "USER_MANAGER_ADD_REQUEST";
export const USER_MANAGER_ADD_SUCCESS = "USER_MANAGER_ADD_SUCCESS";
export const USER_MANAGER_ADD_ERROR = "USER_MANAGER_ADD_ERROR";
export const USER_MANAGER_ADD_ERROR_CONFIRM = "USER_MANAGER_ADD_ERROR_CONFIRM";

export const USER_MANAGER_DELETE_REQUEST = "USER_MANAGER_DELETE_REQUEST";
export const USER_MANAGER_DELETE_SUCCESS = "USER_MANAGER_DELETE_SUCCESS";
export const USER_MANAGER_DELETE_ERROR = "USER_MANAGER_DELETE_ERROR";
export const USER_MANAGER_DELETE_ERROR_CONFIRM =
  "USER_MANAGER_DELETE_ERROR_CONFIRM";

export const USER_EDITOR_DATA_INITIALIZED = "USER_EDITOR_DATA_INITIALIZED";
export const USER_EDITOR_DATA_CHANGED = "USER_EDITOR_DATA_CHANGED";
export const USER_EDITOR_DATA_REVERT_REQUEST =
  "USER_EDITOR_DATA_REVERT_REQUEST";

export const USER_EDITOR_SAVE_REQUEST = "USER_EDITOR_SAVE_REQUEST";
export const USER_EDITOR_SAVE_SUCCESS = "USER_EDITOR_SAVE_SUCCESS";
export const USER_EDITOR_SAVE_ERROR = "USER_EDITOR_SAVE_ERROR";
export const USER_EDITOR_SAVE_ERROR_CONFIRM = "USER_EDITOR_SAVE_ERROR_CONFIRM";

export const USER_PREFERENCE_CHANGE = "USER_PREFERENCE_CHANGE";
export const USER_PREFERENCE_SAVE_SUCCESS = "USER_PREFERENCE_SAVE_SUCCESS";
export const USER_PREFERENCE_SAVE_ERROR = "USER_PREFERENCE_SAVE_ERROR";
