import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

import { Button } from "@material-ui/core";

import { Dialog } from "components";
import ContentContainer from "modules/ContentContainer";
import { instanceBrowserMapStateToProps } from "mapToProps/instanceBrowser";
import ActionFormModal from "../ActionFormModal";

import {
  CardBrowser,
  CardBrowserSlider,
  CardBrowserFilter,
  CardBrowserSearch,
  CardBrowserSelectedDisplay
} from "components";

import * as storyActions from "actions/storyEditor";
import * as installationActions from "actions/installations";
import "./InstanceBrowser.scss";

const installationAddForm = {
  title: {
    type: "text",
    isRequired: true,
    label: "Title",
    param: "title",
    value: "",
    inlineLabel: true
  }
};

const projectAddForm = {
  title: {
    type: "text",
    isRequired: true,
    label: "Title",
    param: "title",
    value: "",
    inlineLabel: true
  },
  type: {
    type: "radio",
    isRequired: true,
    label: "Story Type",
    param: "type",
    value: "",
    options: [
      {
        value: "story",
        label: "Story"
      },
      {
        value: "collection",
        label: "Collection"
      }
    ]
  }
};

const storyAddForm = {
  title: {
    type: "text",
    isRequired: true,
    label: "Title",
    param: "title",
    value: "",
    inlineLabel: true
  },
  type: {
    type: "radio",
    isRequired: true,
    label: "Story Type",
    param: "type",
    value: "",
    options: [
      {
        value: "gallery",
        label: "Gallery"
      },
      {
        value: "slideshow",
        label: "Slideshow"
      },
      {
        value: "quiz-graded",
        label: "Graded Quiz"
      },
      {
        value: "timeline",
        label: "Timeline"
      },
      {
        value: "map",
        label: "Map"
      }
    ]
  }
};

export class InstanceBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModalOpen: false,
      addModalOpen: false,
      deselectAll: false,
      selected: []
    };

    this.resizingWindow = this.resizingWindow.bind(this);
    this.checkAndUpdateTotalCount = this.checkAndUpdateTotalCount.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.proceedToDelete = this.proceedToDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.exclusiveSelectHandler = this.exclusiveSelectHandler.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
  }

  openAddModal() {
    this.setState({ addModalOpen: true });
  }

  closeAddModal() {
    this.setState({ addModalOpen: false });
  }

  handleAdd(data) {
    this.setState({ addModalOpen: false });

    if (this.props.instanceType === "project") {
      this.props.createNewProject(data);
    } else if (this.props.instanceType === "installation") {
      this.props.createInstallation(data);
    } else if (this.props.instanceType === "story") {
      this.props.createNewStory(data);
    }
  }

  openDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalOpen: false });
  }

  proceedToDelete() {
    this.setState({ deleteModalOpen: false, selected: [] });
    if (this.props.instanceType === "project") {
      this.props.deleteProject();
    } else if (this.props.instanceType === "installation") {
      this.props.deleteInstallation();
    } else {
      this.props.deleteStory();
    }
  }

  exclusiveSelectHandler(selecting) {
    const deselectAll = typeof selecting !== "undefined" && selecting;

    this.setState({
      deselectAll: !deselectAll,
      selected: deselectAll ? { ...this.state.selected, selecting } : []
    });
    if (typeof this.props.exclusiveSelectHandler !== "undefined") {
      this.props.exclusiveSelectHandler(selecting);
    }
  }

  selectHandler(id) {
    this.setState({ deselectAll: false });
    if (typeof this.props.selectHandler !== "undefined") {
      this.props.selectHandler(id);
    }
  }
  render() {
    const topBar = this.props.storyBrowserUIState.selectMode ? (
      <div className="selected-bar">
        <CardBrowserSelectedDisplay
          selectedCount={
            this.props.selected ? this.props.selected.length : undefined
          }
          deselectAllAction={this.exclusiveSelectHandler}
        />
        <div className="delete-asset-button-container">
          <Button
            variant="contained"
            color="primary"
            className="new-asset-button-v2"
            style={{ textTransform: "none" }}
            onClick={e => {
              e.stopPropagation();
              this.openDeleteModal();
            }}
          >
            DELETE
          </Button>
        </div>
      </div>
    ) : (
      <div className="filters-bar-container">
        <CardBrowserSearch
          cardSearchMinWidth={300}
          action={this.props.onChangeSearch}
          search={
            this.props.storyBrowserUIState.keywordSearch
              ? this.props.storyBrowserUIState.keywordSearch
              : ""
          }
          cardSearchHeight={24}
        />
        <div className="filters-bar">
          {this.props.instanceType !== "collection" ? (
            <Button
              variant="contained"
              color="primary"
              className="new-asset-button-v2"
              style={{ textTransform: "none" }}
              onClick={e => {
                e.stopPropagation();
                // NOTE will need a modal eventually
                this.openAddModal();
              }}
            >
              NEW
            </Button>
          ) : null}

          {this.props.instanceType !== "collection" ? (
            <div className="divider" />
          ) : null}

          <CardBrowserSlider
            changeValue={this.props.setSliderMultiplier}
            initialValue={this.props.storyBrowserUIState.cardSizeMultiplier}
          />

          <div className="divider" />

          <CardBrowserFilter
            onSortByClick={this.props.onSortByClick}
            onSortByOrderClick={this.props.onSortByOrderClick}
            sortByTypeOptions={this.props.storyBrowserUIState.sortByTypeOptions}
            sortByType={this.props.storyBrowserUIState.sortByType}
            sortByOrder={this.props.storyBrowserUIState.sortByOrder}
          />
        </div>
      </div>
    );

    let addForm = {};
    // switch (this.props.instanceType) {
    //   case "project":
    //     addForm = projectAddForm;
    //     break;

    //   case "installation":
    //     addForm = installationAddForm;
    //     break;

    //   default:
    //     addForm = storyAddForm;
    //     break;
    // }

    let instanceLabel;
    let instanceLabelPlural;
    switch (this.props.instanceType) {
      case "story":
        addForm = storyAddForm;
        instanceLabel = "Story";
        instanceLabelPlural = "Stories";
        break;
      case "collection":
        instanceLabel = "Collection";
        instanceLabelPlural = "Collections";
        break;
      case "installation":
        addForm = installationAddForm;
        instanceLabel = "Installation";
        instanceLabelPlural = "Installations";
        break;
      case "project":
        addForm = projectAddForm;
        instanceLabel = "Project";
        instanceLabelPlural = "Projects";
        break;
    }

    return (
      <div className="cms-screen story-browser">
        <Helmet>
          <title>{instanceLabel}</title>
        </Helmet>
        <ContentContainer
          isLoading={this.props.storyBrowserUIState.isLoading}
          className="content-container"
          selectMode={this.props.storyBrowserUIState.selectMode}
          sidebarMode={this.props.sidebarMode}
          title={instanceLabelPlural}
          topBar={topBar}
        >
          {this.props.items.length > 0 ? (
            <CardBrowser
              minCardSize={this.props.storyBrowserUIState.cardSizeMultiplier}
              items={this.props.items}
              itemName={instanceLabel}
              setCardMargin={20}
              enableKeys={false}
              deselectAll={this.state.deselectAll}
              editClickHandler={this.props.editClickHandler}
              showButtons
              selectHandler={this.selectHandler}
              selectMode={this.props.storyBrowserUIState.selectMode}
              selected={this.props.selected}
              exclusiveSelectHandler={this.exclusiveSelectHandler}
              backClickHandler={this.props.backClickHandler}
            />
          ) : null}
        </ContentContainer>
        <Dialog
          content="Once deleted, it cannot be restored"
          onConfirm={this.proceedToDelete}
          confirmLabel="Delete"
          onCancel={this.closeDeleteModal}
          open={this.state.deleteModalOpen}
          title={`Delete this ${instanceLabel}?`}
        />
        <ActionFormModal
          open={this.state.addModalOpen}
          closeAction={this.closeAddModal}
          handleSave={this.handleAdd}
          title={`Add New ${instanceLabel}`}
          form={addForm}
        />
      </div>
    );
  }

  componentDidMount() {
    // this should only be called once....
    switch (this.props.instanceType) {
      case "collection":
        this.props.requestCollectionBrowser();
        break;
      case "story":
        this.props.requestStoryBrowser();
        break;
      case "project":
        this.props.requestProjectBrowser();
        break;
      case "installation":
        this.props.requestInstallations();
        break;
    }
    this.props.setSaved(true);
    window.addEventListener("resize", this.resizingWindow);
  }

  // Monitoring this life cycle is necessary because the InstanceBrowser
  // component not remounted when the user switches from root of one
  // section to the root of another section.
  componentWillReceiveProps(nextProps) {
    if (
      this.props.isCollections !== nextProps.isCollections ||
      this.props.isProjects !== nextProps.isProjects ||
      this.props.isInstallations !== nextProps.isInstallations
    ) {
      // NOTE: We can include a mechanism here to check if they have already been loaded and not load again
      // but that, as it happens with the assets, would prevent noticing new additions.
      switch (nextProps.instanceType) {
        case "collection":
          this.props.requestCollectionBrowser();
          break;
        case "story":
          this.props.requestStoryBrowser();
          break;
        case "project":
          this.props.requestProjectBrowser();
          break;
        case "installation":
          this.props.requestInstallations();
      }
    }
  }

  componentDidUpdate() {
    this.checkAndUpdateTotalCount();
    if (this.state.firstLoad) {
      const interval = setInterval(() => {
        const itemsContainerHeight = document.getElementById("itemsContainer")
          ? document.getElementById("itemsContainer").offsetHeight
          : null;
        const cardBrowserHeight = document.getElementById("cardBrowser")
          ? document.getElementById("cardBrowser").offsetHeight
          : null;

        if (itemsContainerHeight && cardBrowserHeight) {
          if (cardBrowserHeight > itemsContainerHeight) {
            if (typeof this.props.executeOnScroll !== "undefined") {
              this.props.executeOnScroll();
            }
          } else {
            clearInterval(interval);
            this.setState({ firstLoad: false });
          }
        }
      }, 300);
    }
  }

  resizingWindow() {
    this.setState({ firstLoad: true });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizingWindow);
  }

  checkAndUpdateTotalCount() {
    if (typeof this.props.storyBrowserUIState === "undefined") return;
    const currentStoredCount = this.props.storyBrowserUIState.totalCount;
    const actualCount = this.props.actualTotalCount;
    if (actualCount > 0 && currentStoredCount !== actualCount) {
      this.props.storyBrowserSetTotalCount(actualCount);
    }
  }
}

InstanceBrowser.contextTypes = {
  router: PropTypes.object
};

InstanceBrowser.defaultProps = {
  isLoading: true,
  storyBrowserUIState: {
    selectMode: false,
    sortByTypeOptions: {
      date: {
        value: "modifiedDate",
        label: "Date Modified"
      },
      title: {
        value: "title",
        label: "Title"
      }
    },
    sortByType: "title",
    sortByOrder: "desc"
  },
  minCardSize: 210
};

InstanceBrowser.propTypes = {
  isLoading: PropTypes.bool,
  instanceType: PropTypes.string, // The type of instance (story, collection, project)
  storyBrowserUIState: PropTypes.object, // represents the node of the store for this section - NOT the data
  sidebarMode: PropTypes.string, // Sidebar Mode
  items: PropTypes.array,
  minCardSize: PropTypes.number,
  storyTypes: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  return instanceBrowserMapStateToProps(state, ownProps);
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    account,
    selected,
    storyTypes,
    projectTypes,
    username,
    cardBrowserConfigUserPrefsSelector
  } = stateProps;
  return Object.assign({}, stateProps, {
    // PROJECTS
    requestProjectBrowser: () => dispatchProps.requestProjectBrowser(username),
    createNewProject: data => {
      const projectTypeID = projectTypes[data.type]._id;
      data.type = projectTypeID;
      dispatchProps.createNewProject(data);
    },
    deleteProject: () => dispatchProps.deleteProject(selected),
    // COLLECTIONS
    requestCollectionBrowser: () =>
      dispatchProps.requestCollectionBrowser(account),
    // INSTALLATIONS
    requestInstallations: () =>
      dispatchProps.installationsFetchRequest(username),
    createInstallation: data => {
      dispatchProps.installationCreateRequest(data);
    },
    // STORY
    requestStoryBrowser: () => dispatchProps.requestStoryBrowser(account),
    createNewStory: data => {
      const storyTypeID = storyTypes[data.type]._id;
      data.type = storyTypeID;
      dispatchProps.createNewStory(data);
    },
    deleteStory: () => dispatchProps.deleteStory(selected),
    // UTILITY
    backClickHandler: id => dispatchProps.exclusiveSelectToggle(id),
    editClickHandler: url => {
      ownProps.history.push(url);
    },
    setSaved: value => dispatchProps.setStoryBrowserIsSaved(value),
    exclusiveSelectHandler: id => dispatchProps.exclusiveSelectToggle(id),
    onChangeSearch: search => dispatchProps.setCardBrowserSearch(search),
    onSortByClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByType",
        value
      ),
    onSortByOrderClick: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "sortByOrder",
        value
      ),
    selectHandler: (id, selector) => dispatchProps.selectToggle(id, selector),
    setSliderMultiplier: value =>
      dispatchProps.dispatchUserPreferenceChange(
        cardBrowserConfigUserPrefsSelector,
        "cardSizeMultiplier",
        value
      ),
    setStoryBrowserIsLoading: value =>
      dispatchProps.setStoryBrowserIsLoading(value),
    storyBrowserSetTotalCount: count =>
      dispatchProps.storyBrowserSetTotalCount(count)
  });
}

export default withRouter(
  connect(
    mapStateToProps,
    { ...storyActions, ...installationActions },
    mergeProps
  )(InstanceBrowser)
);
